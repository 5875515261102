
import { ProfessionalRelationEntityModel, ProfessionalRelationQueryModel } from '@common-src/entity-model/professional-relation-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/org/professional`;

class ProfessionalRelationService implements ICRUDQ<ProfessionalRelationEntityModel, ProfessionalRelationQueryModel> {
    async create(model: ProfessionalRelationEntityModel):Promise<ProfessionalRelationEntityModel> {
        const professionalUrl = `${WORKORDER_BASE_REQUEST_PATH}/professional/save`;
        const professionParams = { id: model.professionalId, name: model.professionalName };
        const result = await post(professionalUrl, professionParams);
        model.professionalId = result;

        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ProfessionalRelationEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ProfessionalRelationEntityModel().toModel(res);
    }

    async update(model: ProfessionalRelationEntityModel):Promise<ProfessionalRelationEntityModel> {
        const professionalUrl = `${WORKORDER_BASE_REQUEST_PATH}/professional/save`;
        const professionParams = { id: model.professionalId, name: model.professionalName };
        await post(professionalUrl, professionParams);
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ProfessionalRelationEntityModel):Promise<ProfessionalRelationEntityModel> {
        const professionalUrl = `${WORKORDER_BASE_REQUEST_PATH}/professional/${model.professionalId}`;
        const result = await del(professionalUrl);
        if (model.id) {
            const url = `${URL_PATH}/delete/${model.id}`;
            const res = await del(url);
            return res;
        } else {
            return result;
        }
    }

    async query(query?: ProfessionalRelationQueryModel, page?: number, limit?: number):Promise<any> {
        const professionalUrl = `${WORKORDER_BASE_REQUEST_PATH}/professional/getModelTree`;
        const professions = await get(professionalUrl);

        const res = [];
        _.forEach(professions, async(profession) => {
            let url = `${URL_PATH}/findByProfessional/${profession.id}`;
            let result = await get(url);
            let modelRes = new ProfessionalRelationEntityModel().toModel(result);
            modelRes.professionalId = profession.id;
            modelRes.professionalName = profession.name;
            res.push(modelRes);
        });
        console.log(res);
        return res;
    }
}

export default new ProfessionalRelationService();
